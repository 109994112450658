import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89')
];

export const server_loads = [3,2,7,5,6];

export const dictionary = {
		"/(frontend)": [~27,[3]],
		"/(frontend)/archiv": [51,[3]],
		"/(frontend)/(content-routes)/chat": [28,[3]],
		"/(frontend)/(content-routes)/detail/announcement/[id]": [~29,[3]],
		"/(frontend)/(content-routes)/detail/notification": [~30,[3]],
		"/(development)/dev": [9,[2]],
		"/(development)/dev/accordion": [10,[2]],
		"/(development)/dev/addmenu": [11,[2]],
		"/(development)/dev/alerts": [12,[2]],
		"/(development)/dev/api-test": [~13,[2]],
		"/(development)/dev/assistant": [~14,[2]],
		"/(development)/dev/buttons": [15,[2]],
		"/(development)/dev/cards": [16,[2]],
		"/(development)/dev/dialog-old": [~18,[2]],
		"/(development)/dev/dialog": [~17,[2]],
		"/(development)/dev/form-multi-page": [~19,[2]],
		"/(development)/dev/icons": [20,[2]],
		"/(development)/dev/inputs": [~21,[2]],
		"/(development)/dev/lists": [22,[2]],
		"/(development)/dev/menus": [23,[2]],
		"/(development)/dev/tabs": [24,[2]],
		"/(development)/dev/various": [25,[2]],
		"/(development)/dev/widgets": [26,[2]],
		"/einrichtungsassistent": [~89,[8]],
		"/(frontend)/(content-routes)/forms/announcement/[[id]]": [31,[3]],
		"/(frontend)/(content-routes)/forms/documentFile/[[id]]": [39,[3]],
		"/(frontend)/(content-routes)/forms/document/companyAgreement/[[id]]": [32,[3]],
		"/(frontend)/(content-routes)/forms/document/correspondence/[[id]]": [33,[3]],
		"/(frontend)/(content-routes)/forms/document/general/[[id]]": [34,[3]],
		"/(frontend)/(content-routes)/forms/document/invitation/[[id]]": [35,[3]],
		"/(frontend)/(content-routes)/forms/document/protocol/[[id]]": [36,[3]],
		"/(frontend)/(content-routes)/forms/document/resolution/[[id]]": [37,[3]],
		"/(frontend)/(content-routes)/forms/document/wageAgreement/[[id]]": [38,[3]],
		"/(frontend)/(content-routes)/forms/event/[[id]]": [40,[3]],
		"/(frontend)/(content-routes)/forms/mandator": [~41,[3]],
		"/(frontend)/(content-routes)/forms/phoneCall/[[id]]": [42,[3]],
		"/(frontend)/(content-routes)/forms/photoAdd/[id]": [~43,[3]],
		"/(frontend)/(content-routes)/forms/photoDocumentAdd": [~44,[3]],
		"/(frontend)/(content-routes)/forms/photoDocumentEdit/[id]": [~45,[3]],
		"/(frontend)/(content-routes)/forms/role/[[id]]": [46,[3]],
		"/(frontend)/(content-routes)/forms/task/[[id]]": [47,[3]],
		"/(frontend)/(content-routes)/forms/team/[[id]]": [~48,[3]],
		"/(frontend)/(content-routes)/forms/user/settings": [50,[3]],
		"/(frontend)/(content-routes)/forms/user/[[id]]": [49,[3]],
		"/(login)/login": [~83,[7]],
		"/(login)/login/2fa": [~84,[7]],
		"/(login)/logout": [~85,[7]],
		"/(frontend)/meine-arbeit": [~52,[3,4]],
		"/(frontend)/meine-arbeit/aufgaben": [~53,[3,4]],
		"/(frontend)/meine-arbeit/dokumente": [~54,[3,4]],
		"/(frontend)/meine-arbeit/dokumente/allgemein": [~55,[3,4]],
		"/(frontend)/meine-arbeit/dokumente/einladungen": [~56,[3,4]],
		"/(frontend)/meine-arbeit/dokumente/protokolle": [~57,[3,4]],
		"/(frontend)/meine-arbeit/dokumente/schriftwechsel": [~58,[3,4]],
		"/(frontend)/meine-arbeit/telefonliste": [~59,[3,4]],
		"/(frontend)/meine-arbeit/termine": [~60,[3,4]],
		"/(login)/password-forgot": [~86,[7]],
		"/(login)/password-reset/[token]": [~87,[7]],
		"/(login)/refresh": [~88,[7]],
		"/(frontend)/sekretariat": [~61,[3,5]],
		"/(frontend)/sekretariat/ankuendigungen": [~62,[3,5]],
		"/(frontend)/sekretariat/berichte": [63,[3,5]],
		"/(frontend)/sekretariat/einstellungen": [~64,[3,5]],
		"/(frontend)/sekretariat/funktionen": [~65,[3,5]],
		"/(frontend)/sekretariat/personen": [~66,[3,5]],
		"/(frontend)/sekretariat/teams": [~67,[3,5]],
		"/(frontend)/team/[id]": [~68,[3,6]],
		"/(frontend)/team/[id]/aufgaben": [~69,[3,6]],
		"/(frontend)/team/[id]/dokumente": [~70,[3,6]],
		"/(frontend)/team/[id]/dokumente/allgemein": [~71,[3,6]],
		"/(frontend)/team/[id]/dokumente/beschluesse": [~72,[3,6]],
		"/(frontend)/team/[id]/dokumente/betriebsvereinbarungen": [~73,[3,6]],
		"/(frontend)/team/[id]/dokumente/einladungen": [~74,[3,6]],
		"/(frontend)/team/[id]/dokumente/protokolle": [~75,[3,6]],
		"/(frontend)/team/[id]/dokumente/schriftwechsel": [~76,[3,6]],
		"/(frontend)/team/[id]/dokumente/tarifvertraege": [~77,[3,6]],
		"/(frontend)/team/[id]/fotos": [~78,[3,6]],
		"/(frontend)/team/[id]/fotos/[documentId]": [~79,[3,6]],
		"/(frontend)/team/[id]/mitglieder": [~80,[3,6]],
		"/(frontend)/team/[id]/telefonliste": [~81,[3,6]],
		"/(frontend)/team/[id]/termine": [~82,[3,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';